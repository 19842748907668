$color_primary: #357fa9;
$color_secondary: #3c8dbc;
$color_tertiary: #03A9F4;
$color_quaternary: #333A73;
$color_fifth: TBD;

$colors: (
  'colorPrimary': $color_primary,
  'colorSecondary': $color_secondary,
  'colorTertiary': $color_tertiary,
  'colorQuaternary': $color_quaternary,
  'colorFifth': $color_fifth
);

:export {
  colorPrimary: $color_primary;
  colorSecondary: $color_secondary;
  colorTertiary: $color_tertiary;
  colorQuaternary: $color_quaternary;
  colorFifth: $color_fifth;
}