@import './variables';

$draggable-space: 10px;

.label {
  svg {
    color: $color_secondary;
    margin-right: 8px;
  }
}

.highlighted {
  background-color: #cde9ff;
}

.draggable-table-container {
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  .table-container {
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE */
    user-select: none; /* Standard syntax */
    
    .table-content {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      overflow-y: auto;
    
      table {   
        table-layout: fixed;
        display: block;

        thead {
          th {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        tbody {
          tr {
            height: 25px
          }

          td {
            text-align: center;

            &:first-child {
              border: 1px solid grey;
              border-right: 0px;
            }
          }
        }
      }
    }
  }
}

#resizable-div {
  position: absolute;
  display: flex;
  flex-direction: row;
  resize: both;

  &:focus-visible {
    outline: none;
  }

  #top-side {
    height: $draggable-space;
    opacity: 0;

    &:hover {
      cursor: ns-resize;
    }
  }

  #bottom-side {
    height: $draggable-space;
    opacity: 0;

    &:hover {
      cursor: ns-resize;
    }
  }

  #left-side {
    width: $draggable-space;
    opacity: 0;

    &:hover {
      cursor: ew-resize;
    }
  }

  #right-side {
    width: $draggable-space;
    opacity: 0;

    &:hover {
      cursor: ew-resize;
    }
  }

  .corner {
    width: $draggable-space;
    height: $draggable-space;
  }

  .top-left-corner {
    &:hover {
      cursor: nwse-resize;
    }
  }

  .top-right-corner {
    &:hover {
      cursor: nesw-resize;
    }
  }

  .bottom-left-corner {
    &:hover {
      cursor: nesw-resize;
    }
  }

  .bottom-right-corner {
    &:hover {
      cursor: nwse-resize;
    }
  }

  #content-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: move;
    }
  }

  .side-container {
    display: flex;
    flex-direction: column;
  }

  .w-sm {
    width: $draggable-space;
  }
}